import React, { lazy, Suspense, useEffect } from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ChakraProvider } from "@chakra-ui/react";
import 'react-toastify/dist/ReactToastify.css';
import "./css/index.css";

import PageNotFound from './components/PageNotFound/PageNotFound'
import HomePage from './pages/Home/Home'
import Deposit from './pages/DepositPage/Deposit'
import Withdraw from './pages/WithdrawPage/Withdraw'
import UploadTutorial from './pages/UploadTutorial/UploadTutorial'
import UploadedVideos from './pages/UploadedVideos/UploadedVideos';
import ViewVideo from './pages/ViewVideo/ViewVideo';
import User from './pages/UserPage/User';
import TransactionHistory from './pages/TransactionHistoryPage/TransactionHistory';
import Package from './pages/PackagePage/Package';
import UploadPackage from './pages/UploadPackage/UploadPackage';
import AddAgent from './pages/AddAgent/AddAgent';
import Agent from './pages/Agent/Agent'
import Payment from './pages/Payment/Payment';
import UploadPaymentQr from './pages/UploadPaymentQr/UploadPaymentQr';
import CreateAdmin from './pages/CreateAdmin/CreateAdmin';
import AdminList from './pages/AdminList/AdminList';
import EmailTemplate from './pages/EmailTemplate/EmailTemplate';
import Email from './Email/Email';
import EditPackage from './pages/EditPackage/EditPlan';
import Profit from './pages/Profit/Profit';
import Referral from './pages/Referral/Referral.jsx'
import Spinner from './components/Spinner/Spinner.jsx';

const Login = lazy(() => import('./components/Login/Login'));
const Dashboard = lazy(() => import('./Dashboard'));
const Home = lazy(() => import('./components/Homepage/Home'));
const Faqone = lazy(() => import("./components/FaqPage/Faqone"));
const Faqtwo = lazy(() => import("./components/FaqPage/Faqtwo"));
const Faqthree = lazy(() => import("./components/FaqPage/Faqthree"));
const Privacy = lazy(() => import("./components/privacy/Privacy"));
const Terms = lazy(() => import("./components/Terms&service/Terms"));


const router = createBrowserRouter(
  [
    {
      path: '/admin',
      element: <Dashboard />,
      children: [
        {
          path: "/admin",
          element: <HomePage />
        },
        {
          path: "users",
          element: <User />
        },
        {
          path: "transaction-history/:id",
          element: <TransactionHistory />
        },
        {
          path: "deposits",
          element: <Deposit />
        },
        {
          path: "withdraw",
          element: <Withdraw />
        },
        {
          path: "upload-video",
          element: <UploadTutorial />
        },
        {
          path: "uploaded-video",
          element: <UploadedVideos />
        },
        {
          path: "view-video",
          element: <ViewVideo />
        },
        {
          path: "plans",
          element: <Package />
        },
        {
          path: "create-plans",
          element: <UploadPackage />
        },
        {
          path: "add-agent",
          element: <AddAgent />
        },
        {
          path: "agents",
          element: <Agent />
        },
        {
          path: "add-qr",
          element: <UploadPaymentQr />
        },
        {
          path: "payments",
          element: <Payment />
        },
        {
          path: "create-admin",
          element: <CreateAdmin />
        },
        {
          path: "view-admin",
          element: <AdminList />
        },
        {
          path: "email",
          element: <EmailTemplate />
        },
        {
          path: "send-mail",
          element: <Email />
        },
        {
          path: "edit-plan/:id",
          element: <EditPackage />
        },
        {
          path: "profit",
          element: <Profit />
        },
        {
          path: "referral",
          element: <Referral />
        },
      ]
    },
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/faq-one',
      element: <Faqone />
    },
    {
      path: '/faq-two',
      element: <Faqtwo />
    },
    {
      path: '/faq-three',
      element: <Faqthree />
    },
    {
      path: '/termas-a',
      element: <Terms />
    },
    {
      path: '/privacy-p',
      element: <Privacy />
    },
    {
      path: '*',
      element: <PageNotFound />
    },

  ]
);

function App() {
  useEffect(() => {
    if (navigator.onLine === false) {
      toast.error("NO INTERNET CONNECTION");
    }
  }, []);
  return (
    <Suspense fallback={<Spinner />}>
      <ChakraProvider>
        <RouterProvider router={router} />
        <ToastContainer />
      </ChakraProvider>
    </Suspense>
  );
}

export default App;
