import React, { useEffect, useState } from 'react'
import { domain } from '../../constant'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { getToken } from '../../util'

const TransactionHistory = () => {
    const [transactions, setTransactions] = useState([])
    const baseUrl = domain+'/admin'
    const {id} = useParams()
    const getTransactions = async () => {
        try {
            const res = await axios.get(`${baseUrl}/get-transaction/${id}`, {
                headers: {
                    'x-access-token': getToken()
                }
            })
            setTransactions(res.data)
        }catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getTransactions()
    }, [])
  return (
      <div id="page-content-wrapper">
      <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 deposit-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Users</h4>
            {/* <form onSubmit={submitHandler}>
              <div className="row align-items-center justify-content-end">
                <div className="col-4">
                  <Input
                    labelText={"Search Email"}
                    placeholderText={"Enter your email"}
                    type={"search"}
                    name={"search"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-2">
                  <button className="btn btn-primary mt-2" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </form> */}

            <div className="table-responsive deposit-table">
              <table className="table table-striped border">
                <caption>List of transaction</caption>
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Amount</th>
                    <th scope="col">WA/TID</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Type</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions &&
                    transactions.length > 0 &&
                    transactions.map((transaction) => (
                      <tr>
                        <td>{transaction._id}</td>
                        <td>{transaction.amount}</td>
                        {
                            transaction.type === 'deposit' ? 
                            <td>{transaction.transactionId}</td>:
                            <td>{transaction.walletAddress}</td>
                        }
                        <td>
                        {transaction.status !== "pending" &&
                            transaction.status !== "failed" && (
                              <b className="text-success">success</b>
                            )}
                          {transaction.status === "pending" && (
                            <b className="text-warning">pending</b>
                          )}
                          {transaction.status === "failed" && (
                            <b className="text-danger">failed</b>
                          )}
                        </td>
                        <td>{new Date(transaction.createdAt).toString()}</td>
                        <td>
                            {transaction.type === 'withdraw' ?
                            <b className='text-info'>withdraw</b>
                            :
                            <b className='text-primary'>deposit</b>
                          }
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  )
}

export default TransactionHistory