import React, { useState } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import axios from "axios";
import {domain} from '../../constant'
import { getToken } from "../../util";
import Spinner from "../../components/Spinner/Spinner";

const UploadTutorial = () => {
    const baseUrl = `${domain}/admin`
    const [video, setVideo] = useState([])
    const [thumbnail, setThumbnail] = useState([])
    const [videoType, setVideoType] = useState('deposit')
    const [loading, setLoading] = useState(false)

  const videoSelectHandler = (e) => {
    setVideo(Array.from(e.target.files))
  };

  const thumbnailSelectHandler = (e) => {
    setThumbnail(Array.from(e.target.files))
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const res = await axios.post(`${baseUrl}/upload-tutorials`, {
            tutorial: video,
            thumbnail: thumbnail,
            type: videoType
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': getToken()
            }
        })
        if(res.status === 200) {
            alert(res.data)
        }
    }catch(err) {
      alert("Something went wrong")  
      console.log(err)
    }
    setLoading(false)
  };

  const handleChange = (e) => {
    setVideoType(e.target.value)
  }

  if(loading) {
    return <Spinner />
  }
  return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 upload-form-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Upload</h4>
            <form onSubmit={submitHandler} className="fileUploadForm">
            <div className="mt-3">
                <select name="type" id="type" className="form-control" onChange={handleChange} defaultValue={videoType}>
                  <option value="deposit">Deposit</option>
                  <option value="withdraw">Withdraw</option>
                  <option value="investors">Investors</option>
                </select>
              </div>
              <div className="mt-3">
                <Input
                  labelText={"Upload Video"}
                  placeholderText={"Upload Video"}
                  type={"file"}
                  name={"tutorials"}
                  onChange={videoSelectHandler}
                  accept={"video/*"}
                  multiple={"multiple"}
                />
              </div>
              <div className="mt-3">
                <Input
                  labelText={"Upload Thumbnail"}
                  placeholderText={"Upload Thumbnail"}
                  type={"file"}
                  name={"tutorials"}
                  onChange={thumbnailSelectHandler}
                  accept={"image/*"}
                  multiple={"multiple"}
                />
              </div>
              <Button displayText={"Upload"} color={"white"} />
            </form>
          </div>
        </div>
      </div>
 
  );
};

export default UploadTutorial;
