import React, { useEffect, useState, useMemo } from "react";
import { getToken } from "../../util";
import axios from "axios";
import Input from "../../components/Input/Input";
import { domain } from '../../constant'
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";


const Withdraw = () => {

  const baseUrl = domain + '/admin'
  const [withdraw, setWithdraw] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [search, setSearch] = useState([]);
  const [loading, setloading] = useState(false)

  const fetchWithdraw = async () => {
    setloading(true)
    try {
      const res = await axios.get(`${baseUrl}/get-withdraw-request`, {
        headers: {
          'x-access-token': getToken()
        }
      })
      const data = res.data
      setWithdraw(data)
      setSearch(data)
      setloading(false)
    } catch (err) {
      setloading(false)
      console.log(err)
    }
  }
  useEffect(() => {
    fetchWithdraw()
  }, [])


  const acceptRejectClickHandler = async (e, withdrawId, status) => {
    e.preventDefault();
    try {
      const res = await axios.put(
        `${baseUrl}/approve-reject-withdraw-request/${withdrawId}`,
        {
          status,
        },
        {
          headers: {
            'x-access-token': getToken()
          }
        }
      );
      console.log(res)
      if (res.status === 200) {
        window.location.reload();
      } else {
        alert("something went wrong");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const searchHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await axios.get(`${domain}/admin/search-withdraw?q=${searchValue}`)
      setWithdraw(res.data)
    } catch (err) {
      console.log(err)
    }
  }
  const debouncedSearch = useMemo(() => handleSearch, [searchValue]);

  function handleSearch(value) {
    if (value !== "") {
      setSearch(
        withdraw.filter(
          (d) =>
            d?.userId?.fullName.toLowerCase().includes(value.toLowerCase()) ||
            d?.walletAddress?.toLowerCase().includes(value.toLowerCase()) ||
            d?.status?.toLowerCase().includes(value.toLowerCase()) ||
            d?.method?.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setSearch(withdraw);
    }
  }

  useEffect(() => {
    debouncedSearch(searchValue.trim());
  }, [searchValue, debouncedSearch]);


  return (
    loading ? <Spinner /> :
      (<div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 deposit-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Withdraw</h4>
            <form onSubmit={searchHandler}>
              <div className="row align-items-center justify-content-end">
                <div className="col-4">
                  <Input
                    labelText={"Search"}
                    placeholderText={"Enter value"}
                    type={"search"}
                    name={"search"}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                {/* <div className="col-2">
                  <button className="btn btn-primary mt-2" type="submit">
                    Search
                  </button>
                </div> */}
              </div>
            </form>

            <div className="table-responsive deposit-table">
              <table className="table table-striped border">
                <caption>List of withdraw</caption>
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">User</th>
                    <th scope="col">Wallet Address</th>
                    <th scope="col">Method</th>
                    <th scope="col">Amount in dollar($)</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    search &&
                    search.length > 0 &&
                    search.map((withdraw) => (
                      <tr>
                        <td>{withdraw._id}</td>
                        <td>{withdraw?.userId?.fullName}</td>
                        <td>{withdraw.walletAddress}</td>
                        <td>{withdraw.method}</td>
                        <td>{withdraw.amount}</td>
                        <td>
                          {withdraw.status !== "pending" &&
                            withdraw.status !== "failed" && (
                              <b className="text-success">success</b>
                            )}
                          {withdraw.status === "pending" && (
                            <b className="text-warning">pending</b>
                          )}
                          {withdraw.status === "failed" && (
                            <b className="text-danger">failed</b>
                          )}
                        </td>
                        <td>{new Date(withdraw.createdAt).toDateString()}</td>
                        <td>
                          {withdraw.status !== "success" &&
                            withdraw.status !== "failed" && (

                              <div className="d-flex align-items-center justify-content-around">
                                <Button
                                  clickHandler={(e) =>
                                    acceptRejectClickHandler(e, withdraw._id, 'success')
                                  }
                                  displayText="Accept"
                                  color="white"
                                  bgColor="#017BFE"
                                />
                                <Button
                                  clickHandler={(e) =>
                                    acceptRejectClickHandler(e, withdraw._id, 'failed')
                                  }
                                  displayText="Reject"
                                  color="white"
                                  bgColor="#14A2B8"
                                />
                              </div>

                            )}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)
  );
};

export default Withdraw;
