import React, { useState } from "react";
import { getToken } from "../util";
import axios from 'axios'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { domain } from '../constant'
import Spinner from "../components/Spinner/Spinner";


const Email = () => {
  const initialState = {
    to: '',
    about: '',
    from: 'support@afri-investraders.com'
  }
  const [content, setContent] = useState('')
  const [formData, setFormData] = useState(initialState)
  const [loading, setLoading] = useState(false)

  const changeHandler = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const submitHandler = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      const res = await axios.post(`${domain}/admin/send-mail`, { ...formData, content }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      if (res.status === 200) {
        alert("Mail sended successfully")
        setFormData(initialState)
        setContent("")
      }
    } catch (err) {
      console.log(err)
      alert("Something went wrong")
    }
    setLoading(false)
  }
  if (loading) {
    return <Spinner />
  }
  return (
    <div id="page-content-wrapper">
      <form onSubmit={submitHandler}>
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 deposit-container"
            style={{ minHeight: "100vh" }}
          >
            <div className="form-group my-3">
              <label htmlFor="from" className="form-label mb-2">
                From
              </label>
              <input
                type="text"
                placeholder="Enter sender email"
                name="from"
                className="form-control"
                value={'support@invescoqqqafrica.com'}
                readOnly={true}
              />
            </div>
            <div className="form-group my-3">
              <label htmlFor="to" className="form-label mb-2">
                To
              </label>
              <input
                type="text"
                placeholder="Enter receiver email"
                name="to"
                className="form-control"
                onChange={changeHandler}
              />
            </div>
            <div className="form-group my-3">
              <label htmlFor="about" className="form-label mb-2">
                Title
              </label>
              <input
                type="text"
                placeholder="Enter title"
                name="about"
                className="form-control"
                onChange={changeHandler}
              />
            </div>
            <div className="form-group">
              <label htmlFor="content" className="form-label mb-2">
                Content
              </label>
              <div className="form-group">
                <ReactQuill
                  placeholder="Enter your rich text edtior"
                  value={content}
                  onChange={setContent}
                  name="content"
                  className="bg-white text-black"
                  style={{ height: "200px" }}
                />
              </div>
            </div>
            <button className="btn btn-primary mt-5">Send</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Email;
