import React, { useEffect, useState } from "react";
import axios from "axios";
import { domain } from "../../constant";
import { getToken } from "../../util";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";


const UploadedVideos = () => {
  const baseUrl = domain + "/admin";
  const [videos, setVideos] = useState([]);
  const [loading, setloading] = useState(false)

  const getUploadedVideos = async () => {
    try {
      setloading(true)
      const res = await axios.get(`${baseUrl}/uploaded-videos`, {
        headers: {
          "x-access-token": getToken(),
          "content-type": "text/html",
        },
      });
      setVideos(res.data);
      setloading(false)
    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  const deleteHandler = async (e, videoId) => {
    e.preventDefault()
    try {
      const res = await axios.delete(`${baseUrl}/delete-video/${videoId}`, {
        headers: {
          'x-access-token': getToken(),
        }
      })
      if (res.status === 200) {
        alert("Video deleted successfully")
        getUploadedVideos()
      }
    } catch (err) {
      console.log(err)
      alert("Something went wrong")
    }
  }

  useEffect(() => {
    getUploadedVideos();
  }, []);


  return (
    loading ? <Spinner /> :
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 upload-form-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Tutorial Videos</h4>

            <div className="table-responsive deposit-table">
              <table className="table table-striped border">
                <caption>List of videos</caption>
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Url</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    videos &&
                    videos.length > 0 &&
                    videos.map((videos) => (
                      <tr>
                        <td>{videos._id}</td>
                        <td>{videos.name}</td>
                        <td>{videos.type}</td>
                        <td>
                          <Link to={`/admin/view-video?url=${videos.url}`} target="_blank">View</Link>
                        </td>
                        <td>
                          <Button displayText={"Delete"} clickHandler={(e) => deleteHandler(e, videos._id)} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
};

export default UploadedVideos;
