import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { domain } from "../../constant";
import { getToken } from "../../util";
import { Link } from "react-router-dom";
import Input from "../../components/Input/Input";
import Spinner from "../../components/Spinner/Spinner";


const User = () => {
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setloading] = useState(false)

  const baseUrl = domain + "/admin";

  const [search, setSearch] = useState([]);

  const getUsers = async () => {
    setloading(true)
    try {
      const res = await axios.get(`${baseUrl}/get-users`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      setUsers(res.data);
      setSearch(res.data);
      setloading(false)
    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  const changeHandler = async (e, id) => {
    const isBlocked = e.target.checked;
    try {
      const res = await axios.put(
        `${domain}/admin/block-unblock/${id}`,
        { isBlocked },
        {
          headers: {
            "x-access-token": getToken(),
          },
        }
      );
      if (res.status === 200) {
        alert("User Status changed");
      }
      getUsers();
    } catch (err) {
      alert("Something went wrong");
      console.log(err);
    }
  };

  const debouncedSearch = useMemo(() => handleSearch, [searchValue]);

  function handleSearch(value) {
    if (value !== "") {
      setSearch(
        users.filter(
          (u) =>
            u.fullName.toLowerCase().includes(value.toLowerCase()) ||
            u.email.toLowerCase().includes(value.toLowerCase()) ||
            u.phoneNumber.includes(value)
        )
      );
    } else {
      setSearch(users);
    }
  }

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue, debouncedSearch]);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    loading ? <Spinner /> : (<div id="page-content-wrapper">
      <div className="container-fluid">
        <div
          className="mt-5 rounded p-3 deposit-container"
          style={{ minHeight: "100vh" }}
        >
          <h4>Users</h4>

          <div className="row align-items-center justify-content-end">
            <div className="col-4">
              <Input
                labelText={"Search"}
                placeholderText={"Enter search value"}
                type={"search"}
                name={"search"}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            {/* <div className="col-2">
                <button className="btn btn-primary mt-2">Search</button>
              </div> */}
          </div>

          <div className="table-responsive deposit-table">
            <table className="table table-striped border">
              <caption>List of users</caption>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Balance in dollar($)</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Transaction History</th>
                  <th scope="col">Block/Unblock</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  search.length > 0 &&
                  search.map((user) => (
                    <tr>
                      <td>{user._id}</td>
                      <td>{user.fullName}</td>
                      <td>{user.email}</td>
                      <td>
                        {user?.counteryDialNumber} {user.phoneNumber}
                      </td>
                      <td>{user.balance}</td>
                      <td>{new Date(user.createdAt).toString()}</td>
                      <td>
                        <Link to={`/admin/transaction-history/${user._id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <div>
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={(e) => changeHandler(e, user._id)}
                              checked={user?.isBlocked}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>)
  );
};

export default User;
