import React from 'react'
import { useNavigate } from 'react-router-dom';


const PageNotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };


  return (
    <div className='notFound' >
      {/* <img src={img} alt="" /> */}
      <div style={{ marginTop: "50px" }}>
        <h1>404 - Page Not Found</h1>
        <p>Oops! It seems like the page you are looking for does not exist.</p>
        <button onClick={goBack} className='goBack' >Go Back</button>
      </div>
    </div>

  )
}

export default PageNotFound