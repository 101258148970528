import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { domain } from "../../constant";
import { getToken } from "../../util";
import moment from "moment";
import Input from "../../components/Input/Input";
import Spinner from "../../components/Spinner/Spinner";


const Profit = () => {
  const [profit, setProfit] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState([]);
  const [loading, setloading] = useState(false)

  const getProfit = async () => {
    setloading(true)
    try {
      const response = await axios.get(`${domain}/admin/get-profit`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      setProfit(response.data);
      setSearch(response.data);
      setloading(false)
    } catch (error) {
      setloading(false)
      console.log(error)
    }
  };

  useEffect(() => {
    getProfit();
  }, []);

  const debouncedSearch = useMemo(() => handleSearch, [searchValue]);

  function handleSearch(value) {
    if (value !== "") {
      setSearch(
        profit.filter(
          (u) =>
            u.userId?.fullName.toLowerCase().includes(value.toLowerCase()) ||
            u.userId?.email.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setSearch(profit);
    }
  }

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue, debouncedSearch]);





  return (
    loading ? <Spinner /> :
      (<div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="container-fluid">
            <div
              className="mt-5 rounded p-3 deposit-container"
              style={{ minHeight: "100vh" }}
            >
              <h4>Profit</h4>
              <div className="row align-items-center justify-content-end">
                <div className="col-4">
                  <Input
                    labelText={"Search"}
                    placeholderText={"Enter Value"}
                    type={"search"}
                    name={"search"}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                {/* <div className="col-2">
                  <button className="btn btn-primary mt-2" type="submit">
                    Search
                  </button>
                </div> */}
              </div>

              <div className="table-responsive deposit-table">
                <table className="table table-striped border">
                  <caption>List of profits</caption>
                  <thead>
                    <tr>
                      <th scope="col">User Id</th>
                      <th scope="col">Full Name</th>
                      <th scope="col">User Email</th>
                      <th scope="col">Return(%)</th>
                      <th scope="col">Level</th>
                      <th scope="col">Deposit Amount</th>
                      <th scope="col">Profit Amount</th>
                      <th scope="col">Date and Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {search &&
                      search.length > 0 &&
                      search.map((profit) => {
                        if (profit?.userId?._id !== undefined) {
                          return (
                            <tr>
                              <td>{profit?.userId?._id}</td>
                              <td>{profit?.userId?.fullName}</td>
                              <td>{profit?.userId?.email}</td>
                              <td>{profit?.packageId?.return}</td>
                              <td>{profit?.packageId?.level}</td>
                              <td>{profit?.depositAmount}</td>
                              <td>${profit?.amount}</td>
                              <td>{moment(profit.createdAt).format("DD/MM/YYYY hh:mm A z")}</td>
                      
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>)
  );
};

export default Profit;
