import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getToken } from '../../util'
import { domain } from '../../constant'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'

const Package = () => {
  const [packages, setPackages] = useState([])
  const [loading, setloading] = useState(false)

  let navigate = useNavigate();
  const baseUrl = domain + '/api'

  const getPackages = async () => {
    try {
      setloading(true)
      const res = await axios.get(`${baseUrl}/package`, {
        headers: {
          'x-access-token': getToken()
        }
      })

      setPackages(res.data)
      setloading(false)
    } catch (err) {
      setloading(false)
      console.log(err)
    }

  }
  useEffect(() => {
    getPackages()
  }, [])

  const deletePlan = async (e, packageId) => {
    e.preventDefault()
    try {
      const res = await axios.delete(`${baseUrl}/package/${packageId}`, {
        headers: {
          'x-access-token': getToken()
        }
      })
      if (res.status === 200) {
        getPackages()
      }

    } catch (err) {
      alert("Something went wrong")
      console.log(err)
    }
  }

  const editPlan = (e, planId) => {
    navigate(`/admin/edit-plan/${planId}`)
  }

  // const submitHandler = async (e) => {
  //   e.preventDefault()
  //   try {
  //     const res = await axios.get(`${baseUrl}/package/search?q=${search}`)
  //     if(res.status === 200) {
  //       setPackages(res.data)
  //     }
  //   }catch(er) {
  //     console.log(er)
  //     alert("Something went wrong")
  //   }
  // }
  return (
    loading ? <Spinner /> :
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 deposit-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Packages</h4>
            {/* <form onSubmit={submitHandler}>
              <div className="row align-items-center justify-content-end">
                <div className="col-4">
                  <Input
                    labelText={"Search"}
                    placeholderText={"Enter search value"}
                    type={"search"}
                    name={"search"}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="col-2">
                  <button className="btn btn-primary mt-2" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </form> */}

            <div className="table-responsive deposit-table">
              <table className="table table-striped border">
                <caption>List of packages</caption>
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Level</th>
                    <th scope="col">Amount in dollar($)</th>
                    <th scope="col">Return</th>
                    <th scope="col">Referral</th>
                    <th scope="col">Withdrawal</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {packages &&
                    packages.length > 0 &&
                    packages.map((item) => (
                      <tr>
                        <td>{item._id}</td>
                        <td>Level {item.level}</td>
                        <td>{item.amount.min} - {item.amount.max}</td>
                        <td>{item.return}%</td>
                        <td>{item.referral}</td>
                        <td>After {item.withdrawal} days</td>
                        <td>
                          <button className='btn btn-danger' onClick={(e) => deletePlan(e, item._id)}>Delete</button>
                          <button className='btn btn-secondary mx-3' onClick={(e) => editPlan(e, item._id)}>Edit</button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Package