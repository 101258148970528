import React, { useState } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import axios from "axios";
import { domain } from "../../constant";
import { getToken } from "../../util";

const UploadPackage = () => {
  const baseUrl = `${domain}/api/package`;

  const initalState = {
    level: "",
    amount: {
      min: "",
      max: "",
    },
    return: "",
    referral: "",
    withdrawal: "",
  };

  const [plan, setPlan] = useState(initalState)

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${baseUrl}/add`, plan, {
        headers: {
          'x-access-token': getToken()
        }
      })
      if(res.status === 200) {
        alert('Package added successfully')
        resetState()
      }
    }catch(err) {
      console.log(err)
      alert('something went wrong')
    }
  };

  const resetState = () => {
    setPlan(initalState)
  }

  const changeHandler = (e) => {
    const {name, value} = e.target
    if(name === 'min' || name === 'max') {
      setPlan({...plan, amount: {...plan.amount, [name]: value}})
    }else {
      setPlan({...plan, [name]: value})
    }
  };
  return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 upload-form-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Add Plan</h4>
            <form onSubmit={submitHandler} className="fileUploadForm">
              <div className="mt-3">
                <Input
                  labelText={"Level"}
                  placeholderText={"Level"}
                  type={"number"}
                  name={"level"}
                  onChange={changeHandler}
                  value={plan.level}
                />
              </div>
              <div className="mt-3">
                <div className="row">
                  <div className="col">
                    <Input
                      labelText={"Min Amount Range"}
                      placeholderText={"Min Amount Range"}
                      type={"number"}
                      name={"min"}
                      onChange={changeHandler}
                      value={plan.amount.min}
                    />
                  </div>
                  <div className="col">
                    <Input
                      labelText={"Max Amount Range"}
                      placeholderText={"Max Amount Range"}
                      type={"number"}
                      name={"max"}
                      onChange={changeHandler}
                      value={plan.amount.max}
                    />
                  </div>
                </div>
                <div className="mt-3">
                    <Input
                      labelText={"Return"}
                      placeholderText={"Return"}
                      type={"number"}
                      name={"return"}
                      onChange={changeHandler}
                      value={plan.return}
                    />
                  </div>
                  <div className="mt-3">
                    <Input
                      labelText={"Referral %"}
                      placeholderText={"Referral %"}
                      type={"number"}
                      name={"referral"}
                      onChange={changeHandler}
                      value={plan.referral}
                    />
                  </div>
                  <div className="mt-3">
                    <Input
                      labelText={"Withdrawal Days"}
                      placeholderText={"Withdrawal Days"}
                      type={"number"}
                      name={"withdrawal"}
                      onChange={changeHandler}
                      value={plan.withdrawal}
                    />
                  </div>
              </div>
              <Button displayText={"Add Plan"} color={"white"} />
            </form>
          </div>
        </div>
      </div>
  );
};

export default UploadPackage;
