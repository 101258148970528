import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getToken } from '../../util'
import { domain } from '../../constant'
import Spinner from '../../components/Spinner/Spinner'

const Payment = () => {
  const [payments, setPayments] = useState([])
  const [loading, setloading] = useState(false)

  const baseUrl = domain + '/api'
  const getPayments = async () => {
    try {
      setloading(true)
      const res = await axios.get(`${baseUrl}/payment`, {
        headers: {
          'x-access-token': getToken()
        }
      })
      setPayments(res.data)
      setloading(false)
    } catch (err) {
      setloading(false)
      alert('Something went wrong')
      console.log(err)
    }
  }

  const deleteHandler = async (e, id) => {
    try {
      const res = await axios.delete(`${baseUrl}/payment/${id}`, {
        headers: {
          'x-access-token': getToken()
        }
      })
      if (res.status === 200) {
        getPayments()
      }
    } catch (err) {
      console.log(err)
      alert("Something went wrong")
    }
  }
  useEffect(() => {
    getPayments()
  }, [])
  return (

    loading ? <Spinner /> : (<div id="page-content-wrapper">
      <div className="container-fluid">
        <div
          className="mt-5 rounded p-3 deposit-container"
          style={{ minHeight: "100vh" }}
        >
          <h4>Payment Info</h4>
          {/* <form onSubmit={submitHandler}>
              <div className="row align-items-center justify-content-end">
                <div className="col-4">
                  <Input
                    labelText={"Search Email"}
                    placeholderText={"Enter your email"}
                    type={"search"}
                    name={"search"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-2">
                  <button className="btn btn-primary mt-2" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </form> */}

          <div className="table-responsive deposit-table">
            <table className="table table-striped border">
              <caption>List of payments</caption>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Code</th>
                  <th scope="col">Method</th>
                  <th scope="col">Image</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {payments &&
                  payments.length > 0 &&
                  payments.map((item) => (
                    <tr>
                      <td>{item._id}</td>
                      <td>{item.code}</td>
                      <td>{item.method}</td>
                      <td><a href={item.qrImage} alt="qr image" target='__blank'>View</a></td>
                      <td>
                        <button className="btn btn-danger" onClick={(e) => deleteHandler(e, item._id)}>Delete</button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>)
  )
}

export default Payment