import React from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";

const SideBar = () => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const logoutHandler = (e) => {
    localStorage.removeItem("admin-token");
    localStorage.removeItem("admin-info");
    navigate("/login");
  };

  const routes = [
    {
      path: "/admin",
      name: "Dashboard",
      icon: <i className="fa-solid fa-house"></i>,
    },
    {
      path: "/admin/users",
      name: "User",
      icon: <i className="fa-solid fa-users"></i>,
    },
    {
      path: "/admin/deposits",
      name: "Deposit",
      icon: <i className="fa-solid fa-money-bill-transfer"></i>,
    },
    {
      path: "/admin/withdraw",
      name: "Withdraw",
      icon: <i className="fa-solid fa-money-bill"></i>,
    },
    {
      path: "/admin/profit",
      name: "Profit",
      icon: <i className="fa-solid fa-money-bill"></i>,
    },
    {
      path: "/admin/referral",
      name: "Referral",
      icon: <i className="fa-solid fa-money-bill"></i>,
    },
    {
      path: "/admin/upload-video",
      name: "Upload Video",
      icon: <i className="fa-solid fa-upload"></i>,
    },
    {
      path: "/admin/uploaded-video",
      name: "View Video",
      icon: <i className="fa-solid fa-download"></i>,
    },
    {
      path: "/admin/create-plans",
      name: "Upload Plans",
      icon: <i className="fa-solid fa-plus"></i>,
    },
    {
      path: "/admin/plans",
      name: "View Plans",
      icon: <i className="fa-solid fa-eye"></i>,
    },
    {
      path: "/admin/add-agent",
      name: "Add Agent",
      icon: <i className="fa-solid fa-person"></i>,
    },
    {
      path: "/admin/agents",
      name: "View Agent",
      icon: <i className="fa-solid fa-eye"></i>,
    },
    {
      path: "/admin/add-qr",
      name: "Add Qr",
      icon: <i className="fa-solid fa-qrcode"></i>,
    },
    {
      path: "/admin/payments",
      name: "View Payment Qr",
      icon: <i className="fa-solid fa-eye"></i>,
    },
    {
      path: "/admin/create-admin",
      name: "Create Admin",
      icon: <i className="fa-solid fa-lock"></i>,
    },
    {
      path: "/admin/view-admin",
      name: "View Admins",
      icon: <i className="fa-solid fa-eye"></i>,
    },
    {
      path: "/admin/send-mail",
      name: "Send Email",
      icon: <i className="fa-solid fa-plane"></i>,
    },
  ];

  return (
    <div id="sidebar-wrapper" className="sidebar">
      <ul className="sidebar-nav">
        <li className="sidebar-brand">
          <Link to="/admin">
            <div className="purpity-logo-sidebar">
              <span className="letter">
                Welcome{" "}
                {JSON.parse(localStorage.getItem("admin-info")).fullName}
              </span>
            </div>
          </Link>
        </li>

        {routes.map((r, idx) => (
          <li key={idx} className="sidebar-items">
            <NavLink
              to={r.path}
              className={pathname === r.path ? "sidebar-links activeLink" : "sidebar-links"}
            >
              {r.icon}
              <p>{r.name}</p>
            </NavLink>
          </li>
        ))}

        <li className="sidebar-items">
          <Link onClick={logoutHandler} className="sidebar-links">
            <i className="fa-solid fa-right-from-bracket"></i> <p>Logout</p>
          </Link>
        </li>
        <li>
          {/* <div>
          <i className="fa-regular fa-sun"></i>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div> */}
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
