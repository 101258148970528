import React, { useEffect, useState } from "react";
import SideBar from "../../components/SideBar/SideBar";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import axios from "axios";
import { domain } from "../../constant";
import utility from "../../util";
import { toast } from "react-toastify";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
const CreateAdmin = () => {
  const intialState = {
    fullName: "",
    email: "",
    password: "",
  };

  const baseUrl = domain + "/admin";
  const [formValue, setFormValue] = useState(intialState);
  const [contactNumber, setContactNumber] = useState("");

  const [country, setCountry] = useState({});

  console.log(country);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const data = {
        ...formValue,
        phoneNumber: contactNumber,
        counteryDialNumber: country.dialCode,
        countryCode: country.iso2,
        countryName: country.name,
      };

      const res = await axios.post(`${baseUrl}/create-admin`, data, {
        headers: {
          "x-access-token": utility.getToken(),
        },
      });

      if (res.status === 200) {
        alert("Admin created successfully");
      }
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.error.message;
        toast.error(errorMessage);
      } else toast.error(err);
    }
  };

  return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="container-fluid">
            <div
              className="mt-5 rounded p-3 upload-form-container"
              style={{ minHeight: "100vh" }}
            >
              <h4>Create Admin</h4>
              <form onSubmit={submitForm} className="fileUploadForm">
                <div className="mt-3">
                  <Input
                    labelText={"Full Name"}
                    placeholderText={"Full Name"}
                    type={"text"}
                    name={"fullName"}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mt-3">
                  <Input
                    labelText={"Email"}
                    placeholderText={"Email"}
                    type={"email"}
                    name={"email"}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mt-3">
                  <Input
                    labelText={"Password"}
                    placeholderText={"Password"}
                    type={"password"}
                    name={"password"}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="my-3 ">
                  <label className="form-label">Phone Number</label>
                  <PhoneInput
                    defaultCountry="ua"
                    value={contactNumber}
                    onChange={(contactNumber, data) => {
                      setCountry(data.country);
                      setContactNumber(contactNumber);
                    }}
                  />
                </div>
                <Button displayText={"Create"} color={"white"} />
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default CreateAdmin;
