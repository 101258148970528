import React from "react";
import ReactPlayer from 'react-player'

const Videos = ({ src }) => {

  return (
    <ReactPlayer url={src} controls={true} height={800} width={1200} />
  );
};

export default Videos;
