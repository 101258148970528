import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { domain } from "../../constant";
import { getToken } from "../../util";

const EmailTemplate = () => {
  const [content, setContent] = useState({});
  const [name, setName] = useState([]);
  const [value, setValue] = useState("");
 

  const getNames = async () => {
    try {
      const res = await axios.get(`${domain}/admin/email-name`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      if (res.status === 200) {
        setName(res.data);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong");
    }
  };

  const getContent = async (name) => {
    try {
      const res = await axios.get(`${domain}/admin/email-by-name/${name}`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      if (res.status === 200) {
        setContent(res.data.content);
      }
    } catch (err) {
      alert("Something went wrong");
      console.log(err);
    }
  };

  const valueChangeHandler = (e) => {
    e.preventDefault();
    getContent(e.target.value);
    setValue(e.target.value)
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
        const res = await axios.put(`${domain}/admin/edit-email`, {name: value, content}, {
            headers: {
                'x-access-token': getToken()
            }
        })
        if(res.status === 200) {
            alert("Template changed successfully")
            getContent(value)
        }
        
    }catch(err) {
        console.log(err)
        alert("Something went wrong")
    }
  }

  useEffect(() => {
    getNames();
  }, []);

  return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 deposit-container"
            style={{ minHeight: "100vh" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="form-group my-4">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <select
                  name="name"
                  className="form-control"
                  onChange={valueChangeHandler}
                >
                  <option value="">--Choose Option--</option>
                  {name.length > 0 &&
                    name.map((value) => (
                      <option value={value.name}>{value.name}</option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <ReactQuill
                  placeholder="Enter your rich text edtior"
                  
                  value={content}
                  onChange={setContent}
                  className="bg-white text-black"
                />
              </div>
              <button className="mt-5 btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
  );
};

export default EmailTemplate;
