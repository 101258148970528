import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { getToken } from "../../util";
import { domain } from "../../constant";
import Input from "../../components/Input/Input";
import Spinner from "../../components/Spinner/Spinner";

const Agent = () => {
  const [agents, setAgents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState([]);
  const [loading, setloading] = useState(false)

  const baseUrl = domain + "/api";
  const getAgents = async () => {
    try {
      setloading(true)
      const res = await axios.get(`${baseUrl}/agent`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      setAgents(res.data);
      setSearch(res.data)
      setloading(false)
    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  const deleteHandler = async (e, id) => {
    try {
      const res = await axios.delete(`${domain}/api/agent/${id}`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      if (res.status === 200) {
        alert("Agent Deleted");
      }
      getAgents();
    } catch (err) {
      alert("Something went wrong");
      console.log(err);
    }
  };

  useEffect(() => {
    getAgents();
  }, []);

  const searchHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.get(`${domain}/api/agent/search?q=${search}`);
      if (res.status === 200) {
        setAgents(res.data);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong");
    }
  };

  const debouncedSearch = useMemo(() => handleSearch, [searchValue]);

  function handleSearch(value) {
    if (value !== "") {
      setSearch(
        agents.filter(
          (u) =>
            u.name.toLowerCase().includes(value.toLowerCase()) ||
            u.btc.toLowerCase().includes(value.toLowerCase()) ||
            u.usdt.toLowerCase().includes(value.toLowerCase()) ||
            u.contactNumber.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setSearch(agents);
    }
  }

  useEffect(() => {
    debouncedSearch(searchValue.trim());
  }, [searchValue, debouncedSearch]);

  return (
    loading ? <Spinner /> :
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 deposit-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Agents</h4>
            <div className="row align-items-center justify-content-end">
              <div className="col-4">
                <Input
                  labelText={"Search Search"}
                  placeholderText={"Enter search value"}
                  type={"search"}
                  name={"search"}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {/* <div className="col-2">
                <button className="btn btn-primary mt-2" type="submit">
                  Search
                </button>
              </div> */}
            </div>

            <div className="table-responsive deposit-table">
              <table className="table table-striped border">
                <caption>List of agents</caption>
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Amount in dollar($)</th>
                    <th scope="col">Rate</th>
                    <th scope="col">BTC</th>
                    <th scope="col">USDT</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Block/Unblock</th>
                  </tr>
                </thead>
                <tbody>
                  {search &&
                    search.length > 0 &&
                    search.map((item) => (
                      <tr>
                        <td>{item._id}</td>
                        <td>{item.name}</td>
                        <td>
                          {item.amount.min} - {item.amount.max}
                        </td>
                        <td>{item.rate}</td>
                        <td>{item.btc}</td>
                        <td>{item.usdt}</td>
                        <td>{item.contactNumber}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => deleteHandler(e, item._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Agent;
