import React, { useEffect, useState, useMemo } from "react";
import SideBar from "../../components/SideBar/SideBar";
import axios from "axios";
import { domain } from "../../constant";
import { getToken } from "../../util";
import Input from "../../components/Input/Input";
import Spinner from "../../components/Spinner/Spinner";

const AdminList = () => {
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState([]);
  const [loading, setloading] = useState(false)

  const baseUrl = domain + "/admin";
  const getUsers = async () => {
    try {
      setloading(true)
      const res = await axios.get(`${baseUrl}/admin-list`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      setUsers(res.data);
      setSearch(res.data);
      setloading(false)
    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  const searchHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.get(`${domain}/admin/search?q=${searchValue}`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      setUsers(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const changeHandler = async (e, id) => {
    const isBlocked = e.target.checked;
    try {
      const res = await axios.put(
        `${domain}/admin/block-unblock-admin/${id}`,
        { isBlocked },
        {
          headers: {
            "x-access-token": getToken(),
          },
        }
      );
      if (res.status === 200) {
        alert("User Status changed");
      }
      getUsers();
    } catch (err) {
      alert("Something went wrong");
      console.log(err);
    }
  };

  const debouncedSearch = useMemo(() => handleSearch, [searchValue]);

  function handleSearch(value) {
    if (value !== "") {
      setSearch(
        users.filter(
          (u) =>
            u.fullName.toLowerCase().includes(value.toLowerCase()) ||
            u.email.toLowerCase().includes(value.toLowerCase()) ||
            u.phoneNumber.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setSearch(users);
    }
  }

  useEffect(() => {
    debouncedSearch(searchValue.trim());
  }, [searchValue, debouncedSearch]);

  return (
    loading ? <Spinner /> :
      (<div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 deposit-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Users</h4>
            <div className="row align-items-center justify-content-end">
              <div className="col-4">
                <Input
                  labelText={"Search"}
                  placeholderText={"Enter search value"}
                  type={"search"}
                  name={"search"}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {/* <div className="col-2">
                <button className="btn btn-primary mt-2" type="submit">
                  Search
                </button>
              </div> */}
            </div>

            <div className="table-responsive deposit-table">
              <table className="table table-striped border">
                <caption>List of users</caption>
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Block/Unblock</th>
                  </tr>
                </thead>
                <tbody>
                  {search &&
                    search.length > 0 &&
                    search.map((user) => (
                      <tr>
                        <td>{user._id}</td>
                        <td>{user.fullName}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{new Date(user.createdAt).toString()}</td>
                        <td>
                          <div>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={(e) => changeHandler(e, user._id)}
                                checked={user?.isBlocked}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)
  );
};

export default AdminList;
