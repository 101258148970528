import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { domain } from "../../constant";
import { getToken } from "../../util";
import moment from "moment";
import Input from "../../components/Input/Input";
import Spinner from "../../components/Spinner/Spinner";

const Referral = () => {
  const [referral, setReferral] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState([]);
  const [loading, setloading] = useState(false)

  const getReferral = async () => {
    try {
      setloading(true)
      const response = await axios.get(`${domain}/admin/get-referral`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      setReferral(response.data);
      setSearch(response.data)
      setloading(false)
    } catch (error) {
      setloading(false)
      console.log(error)
    }
  };

  useEffect(() => {
    getReferral();
  }, []);

  const debouncedSearch = useMemo(() => handleSearch, [searchValue]);

  function handleSearch(value) {
    if (value !== "") {
      setSearch(
        referral.filter(
          (u) =>
            u.referrerId?.fullName.toLowerCase().includes(value.toLowerCase()) ||
            u.referrerId?.email.toLowerCase().includes(value.toLowerCase()) ||
            u.referrerId?.referralCode.toLowerCase().includes(value.toLowerCase()) ||
            u.referredUserId?.fullName.toLowerCase().includes(value.toLowerCase()) ||
            u.referredUserId?.email.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setSearch(referral);
    }
  }

  useEffect(() => {
    debouncedSearch(searchValue.trim());
  }, [searchValue, debouncedSearch]);

  return (
    loading ? <Spinner /> :
      (<div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="container-fluid">
            <div
              className="mt-5 rounded p-3 deposit-container"
              style={{ minHeight: "100vh" }}
            >
              <h4>Referral</h4>
              <div className="row align-items-center justify-content-end">
                <div className="col-4">
                  <Input
                    labelText={"Search"}
                    placeholderText={"Enter Value"}
                    type={"search"}
                    name={"search"}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                {/* <div className="col-2">
                  <button className="btn btn-primary mt-2" type="submit">
                    Search
                  </button>
                </div> */}
              </div>

              <div className="table-responsive deposit-table">
                <table className="table table-striped border">
                  <caption>List of profits</caption>
                  <thead>
                    <tr>
                      <th scope="col">User Id</th>
                      <th scope="col">Referrer Full Name</th>
                      <th scope="col">Referrer User Email</th>
                      <th scope="col">Refer code</th>
                      <th scope="col">Referrer Full Name</th>
                      <th scope="col">Referrer User Email</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date And Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {search &&
                      search.length > 0 &&
                      search.map((referral) => {
                        if (referral?.referrerId?._id) {
                          return (
                            <tr>
                              <td>{referral?.referrerId?._id}</td>
                              <td>{referral?.referrerId?.fullName}</td>
                              <td>{referral?.referrerId?.email}</td>
                              <td>{referral?.referrerId?.referralCode}</td>
                              <td>{referral?.referredUserId?.fullName ? referral?.referredUserId?.fullName : <span className="deleted">Deleted</span>}</td>
                              <td>{referral?.referredUserId?.email ? referral?.referredUserId?.email : <span className="deleted">Deleted</span>}</td>
                              <td>${referral?.rewardedAmount}</td>
                              <td>
                                {moment.utc(referral?.createdAt).format("ddd MMM DD YYYY")}
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>)
  );
};

export default Referral;
