


const utility = {}

utility.getToken = () => {
    return JSON.parse(localStorage.getItem("admin-token"))
}

utility.getAdmin = () => {
    return JSON.parse(localStorage.getItem("admin-info"))
}

module.exports = utility