import React, { useState } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import axios from "axios";
import {domain} from '../../constant'
import { getToken } from "../../util";

const UploadPaymentQr = () => {
    const baseUrl = `${domain}/api/payment`
    const [qrImage, setQrImage] = useState(null)
    const [code, setCode] = useState()
    const [method, setMethod] = useState("BTC")
  const uploadQrHandler = (e) => {
    setQrImage(e.target.files[0])
  };

  const formHandler = (e) => {
    setCode(e.target.value)
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
        const res = await axios.post(`${baseUrl}/add`, {
            code,
            method,
            payment: qrImage
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': getToken()
            }
        })
        if(res.status === 200) {
            alert('Qr uploaded successfully')
        }
    }catch(err) {
      alert('something went wrong')
        console.log(err)
    }
  };


  const resetState = () => {
    setCode("")
    setQrImage(null)
  }
  

  return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 upload-form-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Upload</h4>
            <form onSubmit={submitHandler} className="fileUploadForm">
            <div className="mt-3">
               <select className="form-control" name="method" defaultValue={method} onChange={(e) => setMethod(e.target.value)} >
                <option value="BTC">BTC</option>
                <option value="USDT">USDT</option>
               </select>
              </div>
              <div className="mt-3">
                <Input
                  labelText={"Code"}
                  placeholderText={"Code"}
                  type={"text"}
                  name={"code"}
                  onChange={formHandler}
                />
              </div>
              <div className="mt-3">
                <Input
                  labelText={"Upload Qr"}
                  placeholderText={"Upload Qr"}
                  type={"file"}
                  name={"qrImage"}
                  onChange={uploadQrHandler}
                  accept={"image/*"}
                />
              </div>
              <Button displayText={"Upload"} color={"white"} />
            </form>
          </div>
        </div>
      </div>
  );
};

export default UploadPaymentQr;
