import React, { useEffect, useState, useMemo } from "react";
import { getToken } from "../../util";
import axios from "axios";
import Input from "../../components/Input/Input";
import { domain } from "../../constant";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";
import { toast } from 'react-toastify'
import moment from "moment";
import { debounce } from 'lodash';


const Deposits = () => {
  const baseUrl = domain + "/admin";
  const [deposits, setDeposits] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState([]);
  const [loading, setloading] = useState(false)

  const fetchDeposits = async () => {
    setloading(true)
    try {
      const res = await axios.get(`${baseUrl}/get-deposit-request`, {
        headers: {
          "x-access-token": getToken(),
        },
      });
      const data = res.data;
      setDeposits(data);
      setSearch(data);
      setloading(false)
    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  const handleSubscribe = async (data) => {
    try {
      setloading(true)
      const response = await axios.delete(`${domain}/admin/delete-job`, {
        headers: {
          "x-access-token": getToken(),
        },
        data: data,
      });
      setloading(false)
      if (response.data.success) {
        fetchDeposits();
      }
    } catch (error) {
      setloading(false)
      console.log(error)
    }
  };

  const debouncedAcceptRejectClickHandler = debounce(async (e, depositId, status) => {
    e.preventDefault();
    setloading(true)
    try {
      const res = await axios.put(
        `${baseUrl}/approve-reject-deposit-request/${depositId}`,
        {
          status,
        },
        {
          headers: {
            "x-access-token": getToken(),
          },
        }
      );
      if (res.status === 200) {
        fetchDeposits();
      } else {
        setloading(false)
        alert("something went wrong");
      }
    } catch (err) {
      setloading(false)
      if (err?.response?.data) {
        toast.error(err.response.data.error.message)
        return
      }
      console.log(err?.response?.data?.error);
    }
  }, 100); // Adjust the delay as needed

  const acceptRejectClickHandler = (e, depositId, status) => {
    debouncedAcceptRejectClickHandler(e, depositId, status);
  };

  const debouncedSearch = useMemo(() => handleSearch, [searchValue]);

  function handleSearch(value) {
    if (value !== "") {
      setSearch(
        deposits.filter(
          (d) =>
            d?.userId?.fullName.toLowerCase().includes(value.toLowerCase()) ||
            d?.method?.toLowerCase().includes(value.toLowerCase()) ||
            d?.status?.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setSearch(deposits);
    }
  }

  useEffect(() => {
    debouncedSearch(searchValue.trim());
  }, [searchValue, debouncedSearch]);

  console.log(search)

  useEffect(() => {
    fetchDeposits();
  }, []);

  return (
    loading ? <Spinner /> :
      (<div id="page-content-wrapper">
        <div className="container-fluid">
          <div
            className="mt-5 rounded p-3 deposit-container"
            style={{ minHeight: "100vh" }}
          >
            <h4>Deposits</h4>
            <div className="row align-items-center justify-content-end">
              <div className="col-4">
                <Input
                  labelText={"Search"}
                  placeholderText={"Search user name, method or status"}
                  type={"search"}
                  name={"search"}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {/* <div className="col-2">
                <button className="btn btn-primary mt-2" type="submit">
                  Search
                </button>
              </div> */}
            </div>
            <div className="table-responsive deposit-table">
              <table className="table table-striped border">
                <caption>List of deposits</caption>
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">User</th>
                    <th scope="col">Transaction Id</th>
                    <th scope="col">Method</th>
                    <th scope="col">Amount in dollar($)</th>
                    <th scope="col">Package</th>
                    <th scope="col">Return</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {search &&
                    search.length > 0 &&
                    search.map((deposit) => (
                      <tr key={deposit._id}>
                        <td>{deposit._id}</td>
                        <td>{deposit?.userId?.fullName}</td>
                        <td>{deposit.transactionId}</td>
                        <td>{deposit.method}</td>
                        <td>{deposit.amount}</td>
                        <td>Level {deposit?.packageId?.level}</td>
                        <td>{deposit?.packageId?.return}%</td>
                        <td>
                          {deposit.status !== "pending" &&
                            deposit.status === "success" && (
                              <b className="text-success">success</b>
                            )}
                          {deposit.status === "pending" && (
                            <b className="text-warning">pending</b>
                          )}
                          {deposit.status === "Stopped" && (
                            <b className="text-danger">stopped</b>
                          )}
                          {deposit.status === "failed" && (
                            <b className="text-danger">failed</b>
                          )}
                        </td>
                        <td>{moment(deposit.createdAt).format("DD/MM/YYYY hh:mm A z")}</td>
                        <td>
                          {deposit.status == "success" && <Button
                            clickHandler={() => handleSubscribe(deposit)}
                            displayText="Unsubscribe"
                            color="white"
                            bgColor="#49566d"
                          />}

                          {deposit.status !== "success" && deposit.status !== "Stopped" &&
                            deposit.status !== "failed" && (
                              <div className="d-flex align-items-center justify-content-around">
                                <Button
                                  clickHandler={(e) =>
                                    acceptRejectClickHandler(
                                      e,
                                      deposit._id,
                                      "success"
                                    )
                                  }
                                  displayText="Accept"
                                  color="white"
                                  bgColor="#017BFE"

                                />
                                <Button
                                  clickHandler={(e) =>
                                    acceptRejectClickHandler(
                                      e,
                                      deposit._id,
                                      "failed"
                                    )
                                  }
                                  displayText="Reject"
                                  color="white"
                                  bgColor="#14A2B8"
                                />
                              </div>
                            )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)
  );
};

export default Deposits;
