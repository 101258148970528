import React from 'react'
import Videos from '../../components/Videos/Videos'

const ViewVideo = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const url = urlParams.get('url');
  return (
    <div className='showVideos' >
      <Videos src={url} />
    </div>
  )
}

export default ViewVideo