import React, { useState } from "react";
import { domain } from "../../constant";
import Input from "../../components/Input/Input";
import { getToken } from "../../util";
import axios from "axios";
import Button from "../../components/Button/Button";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { toast } from "react-toastify";

const AddAgent = () => {
  const baseUrl = `${domain}/api/agent`;

  const initalState = {
    name: "",
    rate: "",
    usdt: "",
    btc: "",
    amount: {
      min: "",
      max: "",
    },
  };

  const [contactNumber, setContactNumber] = useState("");

  const [agent, setAgent] = useState(initalState);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (!agent.name) {
        toast.error("Please enter Name");
        return;
      }
      if (!agent.amount.min) {
        toast.error("Please enter Min Amount Range");
        return;
      }
      if (!agent.amount.max) {
        toast.error("Please enter Max Amount Range");
        return;
      }
      if (!agent.rate) {
        toast.error("Please enter Rate");
        return;
      }
      if (!agent.usdt) {
        toast.error("Please enter USDT Wallet ID");
        return;
      }
      if (!agent.btc) {
        toast.error("Please enter BTC Wallet ID");
        return;
      }

      if (contactNumber.length < 10) {
        toast.error("Incorrect Contact Number");
        return;
      }


      const res = await axios.post(
        `${baseUrl}/add`,
        { ...agent, contactNumber },
        {
          headers: {
            "x-access-token": getToken(),
          },
        }
      );
      if (res.status === 200) {
        toast.success("Agent added successfully");
        resetState();
      }
    } catch (err) {
      console.log(err);
      toast.error("something went wrong");
    }
  };

  const resetState = () => {
    setAgent(initalState);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "min" || name === "max") {
      setAgent({ ...agent, amount: { ...agent.amount, [name]: value } });
    } else {
      setAgent({ ...agent, [name]: value });
    }
  };
  return (
    <div id="page-content-wrapper">
      <div className="container-fluid">
        <div
          className="mt-5 rounded p-3 deposit-container"
          style={{ minHeight: "100vh" }}
        >
          <h4>Add Agent</h4>
          <form onSubmit={submitHandler} className="fileUploadForm">
            <div className="mt-3">
              <Input
                labelText={"Name"}
                placeholderText={"Name"}
                type={"text"}
                name={"name"}
                onChange={changeHandler}
                value={agent.name}
              />
            </div>
            <div className="mt-3">
              <div className="row">
                <div className="col">
                  <Input
                    labelText={"Min Amount Range"}
                    placeholderText={"Min Amount Range"}
                    type={"number"}
                    name={"min"}
                    onChange={changeHandler}
                    value={agent.amount.min}
                  />
                </div>
                <div className="col">
                  <Input
                    labelText={"Max Amount Range"}
                    placeholderText={"Max Amount Range"}
                    type={"number"}
                    name={"max"}
                    onChange={changeHandler}
                    value={agent.amount.max}
                  />
                </div>
              </div>
              <div className="mt-3">
                <Input
                  labelText={"Rate"}
                  placeholderText={"Rate"}
                  type={"number"}
                  name={"rate"}
                  onChange={changeHandler}
                  value={agent.rate}
                />
              </div>
              <div className="mt-3">
                <Input
                  labelText={"USDT Wallet ID"}
                  placeholderText={"USDT"}
                  type={"text"}
                  name={"usdt"}
                  onChange={changeHandler}
                  value={agent.usdt}
                />
              </div>
              <div className="mt-3">
                <Input
                  labelText={"BTC Wallet ID"}
                  placeholderText={"BTC"}
                  type={"text"}
                  name={"btc"}
                  onChange={changeHandler}
                  value={agent.btc}
                />
              </div>
              <div className="my-3 form-group">
                <label htmlFor="contactNumber" className="form-label">
                  Contact Number
                </label>
                {/* <input
                      placeholder="Contact Number"
                      type="tel"
                      name="contactNumber"
                      onChange={changeHandler}
                      value={agent.contactNumber}
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      className="form-control mb-4"
                    /> */}
                <PhoneInput
                  defaultCountry="ua"
                  value={agent.contactNumber}
                  onChange={(contactNumber) =>
                    setContactNumber(contactNumber)
                  }
                />
              </div>
            </div>
            <Button displayText={"Add Agent"} color={"white"} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAgent;
