import React from "react";
import "./Input.css";
const Input = ({
  icon,
  labelText,
  placeholderText,
  type,
  name,
  value,
  onChange,
  accept,
  multiple
}) => {
  return (
    <div className="form-group mb-3">
      <label className="form-label">{labelText}</label>
      <div className="input-group">
        {icon && (
          <div className="input-group-prepend">
            <span
              className="input-group-text form-control input-icon"
              id="input"
            >
              <img src={icon} alt="login-icon" style={{ width: "25px" }} />
            </span>
          </div>
        )}
        {icon ? (
          <input
            type={type}
            className="form-control shadow-none input-style"
            placeholder={placeholderText}
            aria-label={type}
            aria-describedby="input"
            name={name}
            value={value}
            onChange={onChange}
            accept={accept}
            multiple={multiple}
          />
        ) : (
          <input
            type={type}
            className="form-control shadow-none input-field p-2"
            placeholder={placeholderText}
            aria-label={type}
            aria-describedby="input"
            name={name}
            value={value}
            onChange={onChange}
            accept={accept}
            multiple={multiple}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
