import React from 'react'
import './Button.css'
const Button = ({ displayText, color, bgColor, clickHandler }) => {
  const style = {
    color,
    background: bgColor,
    border: "none"
  }
  return (
    <button onClick={clickHandler} className="btn btn-primary" style={style}>{displayText}</button>
  )
}

export default Button