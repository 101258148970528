import React, { useEffect, useState } from "react";
import axios from "axios";
import { domain } from "../../constant";
import { getToken } from "../../util";
import Spinner from "../../components/Spinner/Spinner";



const Home = () => {
  const baseUrl = domain + "/admin";
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(true)

  const getCount = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${baseUrl}/get-count`, {
        headers: {
          'x-access-token': getToken()
        }
      });
      setCount(response.data);
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  };
  useEffect(() => {
    getCount();
  }, []);
  return (
    loading ? <Spinner /> :
      (<div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="mt-5">
            <div className="row">
              <div className="col-3">
                <div className="card homeCard">
                  <div className="card-body">
                    <h5 className="card-title text-center">Users</h5>
                    <h1 className="card-text text-center text-primary">
                      {count && count.userCount}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card homeCard">
                  <div className="card-body">
                    <h5 className="card-title text-center">Deposits</h5>
                    <h1 className="card-text text-center text-primary">
                      {count && count.depositCount}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card homeCard">
                  <div className="card-body">
                    <h5 className="card-title text-center">Withdraws</h5>
                    <h1 className="card-text text-center text-primary">
                      {count && count.withdrawCount}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)

  );
};

export default Home;
